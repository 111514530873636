@import "../../../assets/variables.scss";

.badge {
  border-radius: 10px;
  padding: 1rem;
  background: white;
  border: $border;
  box-shadow: 5px 5px 5px $grey1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  top: 135px;
  h2 {
    margin-top: 0;
  }
  transition: 0.5s;
  position: fixed;
  right: -230px;
  transition-timing-function: ease-in;

  &.open {
    right: 40px;
  }

  @media screen and (max-width: 481px) {
    h2 {
      font-size: 1rem !important;
    }
    img {
      height: 80px;
    }
  }
}

.badge-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
