@import "../../../assets/variables.scss";

.plan-it-wrap {
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  padding: 0.5em;
  border-radius: 10px;
  border: $border;
  box-shadow: 5px 5px 5px $grey1;
  margin-bottom: 1em;

  .select-section {
    display: flex;
    flex-direction: column;

    .select-item-wrap {
      margin: 0.5em;
      display: flex;
      flex-direction: row;
    }
  }
}

.routine {
  div {
    .item-1 {
      background-color: lightgreen;
      order: 1;
    }
  }

  .select-section {
    order: 2;
  }

  .item-2 {
    order: 3;
  }
}

.other-section {
  width: 50%;
  height: 10em;
}
