@import "./variables.scss";

.button1 {
  border: $border;
  background-color: $dark-blue;
  margin: 0em 1em 1em 1em;
  height: 80%;
  // padding: 0 1em 0 1em;
  color: white;
  border-radius: 10px;
  // box-shadow: 5px 5px 10px rgb(120, 87, 87) ;
  font-size: 1.1em;
  font-weight: 700;
  padding: 0.5em;

  p {
    margin: 0;
    font-size: 1.3em;
  }
}

.button1:hover {
  background-color: $accent;
}

.dragon-button {
  border: $border;
  background-color: $dark-blue;
  border-radius: 10px;
  margin: 0em 1em 1em 1em;

  // box-shadow: 5px 5px 10px rgb(120, 87, 87) ;
  font-size: 1.1em;
  font-weight: 700;
  padding: 0.5em;
  color: black;
}
.dragon-button:hover {
  background-color: $accent;
}

.correct-response {
  margin: 1em;
}


.dragon-button {
  border: $border;
  background-color: $dark-blue;
  border-radius: 10px;
  margin: 0em 1em 1em 1em;

  // box-shadow: 5px 5px 10px rgb(120, 87, 87) ;
  font-size: 1.1em;
  font-weight: 700;
  padding: 0.5em;
  @media screen and (max-width: 481px) {
    font-size: 0.75rem;
  }
}
.dragon-button:hover {
  background-color: $accent;
  color: white;
}

@mixin bigButton($font-color, $border-Color, $hover-color, $hover-background) {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
  font-weight: 600;
  box-shadow: $box-shadow;
  color: $font-color;
  border: $border-Color solid 2px;
  text-decoration: none;
  position: relative;
  transition: 0.3s;
  padding: 0.75rem 1.25rem;
  font-size: 1.25rem;
  &:hover {
    color: $hover-color;
    background: $hover-background;
  }
}

@mixin backAndforth {
  @keyframes backAndforth {
    0%,
    100% {
      transform: translateX(0px);
    }
    50% {
      transform: translateX(10px);
    }
  }
}

@mixin oscillate($animationName, $propertyName) {
  @keyframes #{$animationName} {
    0% {
      #{$propertyName}: $accent;
      border: $accent;
    }

    33% {
      #{$propertyName}: $color1;
      border: $color1;
    }
    66% {
      #{$propertyName}: $dark-blue;
      border: $dark-blue;
    }

    100% {
      #{$propertyName}: $accent;
      border: $accent;
    }
  }
}
