@import '../../assets/variables.scss';
.module-content-wrap{
    display: flex;
    align-items: left;
    text-align: left;
    width: 100%;
}
.module-content-wrap-small{
    // overflow: scroll;
    display: flex;
    align-items: center;
    position: relative; 
    left: 0em;
    height: 100%;
    position: absolute;
    z-index: 10;
    display: none;
}