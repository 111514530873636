@import "../../../assets/variables.scss";
.devtip-wrap {
  // max-width: 30em;
  border-top: solid 2px $accent;
  border-bottom: solid 2px $accent;
  padding: 1em;
  margin-bottom: 1em;
  .head {
    display: flex;
    flex-direction: row;
  }
}
