@use '../../../assets/reusable.scss';
@import '../../../assets/variables.scss';
section {
  width: 100%;
  .title {
    font-size: 2.5rem;
    margin: auto;
  }

  .supportWrapper {
    @include reusable.bigButton(white, $color2, white, $color2);
    background-color: $color2;
    color: white;
    cursor: pointer;
    a {
      padding: 0;
      border: none;
      &:hover {
      }
      p {
        font-size: 1.5rem;
        color: white;
      }
    }
    &:hover {
      background-color: white;
      animation: none;
      box-shadow: 8px 8px $color2;
      p {
        color: $color2;
      }
    }
  }
  .btn-1 {
    @include reusable.bigButton(white, $accent, $accent, white);
    background-color: $accent;
    font-size: 1.5rem;
    margin-bottom: 0;

    &:first-child {
      margin-top: 0;
    }
    &:hover {
      animation: none;
      box-shadow: 8px 8px $accent;
    }
    &.log-out {
      @include reusable.bigButton(white, $dark-blue, $dark-blue, white);
      font-size: 1.5rem;
      background-color: $dark-blue;
      color: white;
      &:hover {
        box-shadow: 8px 8px $dark-blue;
        color: black;
      }
      margin: 0;
    }
  }
  .buttonWrap {
    display: flex;
    gap: 20px;
    padding: 1rem;
    justify-content: center;
    flex-wrap: wrap;
    margin: 1rem 4rem;
    background: #80808014;
    padding: 2rem 1rem;
    border-radius: 10px;
    * {
      margin: 0;
    }
  }
}

.not-complete {
  filter: brightness(0.3) !important;
  pointer-events: none;
}
