.face-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2rem 0;
  > div {
    display: flex;
    gap: 1rem;
  }
}

.face {
  height: 12em;
  width: 10em;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
}
.face:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.face1 {
  background-image: url('../../../assets/images/faces/face-1.png');
  max-height: 14.8em;
  .face-name {
    color: #019966;
  }
}
.face2 {
  background-image: url('../../../assets/images/faces/face-2.png');
  max-height: 14.8em;
  .face-name {
    color: #fbaf43;
  }
}
.face3 {
  background-image: url('../../../assets/images/faces/face-4.png');
  max-height: 14.8em;
  .face-name {
    color: #f05a2d;
  }
}
.face4 {
  background-image: url('../../../assets/images/faces/face-5.png');
  max-height: 14.8em;
  .face-name {
    color: #c02130;
  }
}

.submission-response {
  margin: 0;
  text-align: center;
  padding: 1.5rem;
  background: rgba(38, 226, 38, 0.804);
  color: black;
  margin: 1rem 0;
  font-size: 1rem;
}

.ready-to-complete {
  border-top: 2px dashed #262e68;
  border-bottom: 2px dashed #262e68;
}
