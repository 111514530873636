@import "../../../assets/variables.scss";

.handout-wrap {
  align-items: left;
  justify-content: left;
  display: flex;
  padding: 1rem;
  border-radius: 10px;
  border: $border;
  box-shadow: 5px 5px 5px $grey1;
  margin: 1rem;
  font-size: 1rem;
}

.handout {
  overflow: hidden;
  width: 100%;
  font-size: 1em;
  font-size: 1.25rem;
  font-weight: 600;

  text-align: center;

  > * {
    text-align: left;
  }
  li {
    margin: 0.75rem 0;
    font-weight: 400;
  }
  a {
    text-align: left;
    font-size: 1.25rem;
  }
}
