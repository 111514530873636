@import '../../../assets/variables.scss';

.sidebar-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1000;
  font-family: $font;
  margin-top: $header-height;
}

.sidebar-wrap {
  position: fixed;
  z-index: 100;
  width: $sidebar-width;
  border-right: $border;
  border-top: $border;
  background-color: $accent2;
  display: flex;
  flex-direction: column;
  // min-height: calc(100vh - 3.5em);
  text-decoration: none;
  overflow: auto;
  .icons-box {
    display: none;
  }
  .module-link {
    p {
      font-size: 0.75rem;
    }
  }
  @media screen and (max-width: 970px) {
    position: fixed;
    display: absolute;
    width: auto;
    width: 100%;
    transition: 0.5s;
    &.closed {
      left: -105%;
    }
    &.open {
      left: 0;
    }
    .icons-box {
      height: 3.5em;
      display: flex;
      align-items: center;
      background: white;
      padding-left: 1em;
      gap: 20px;
      padding: 1rem;
      .icon {
        height: auto;
        width: 50px;
        box-sizing: border-box;
        padding: 5px;
        svg {
          path {
            fill: $color2;
          }
          circle {
            fill: $color2;
          }
        }
        &:hover {
          svg {
            path {
              fill: $accent;
            }

            circle {
              fill: $accent;
            }
            rect {
              fill: $accent;
            }
          }
        }
      }
    }
  }
  // padding-bottom: 200em;

  .module-link {
    flex-direction: row;
    text-decoration: none;
    color: white;
    //    width: 100%;
    background-color: $color3;
    border-bottom: $border;
    background-color: $color2;
    text-align: left;
    height: $subheader-height;
    line-height: $subheader-height;
    font-weight: 600;
    text-decoration: none;
    overflow: hidden;
    display: flex;
    line-height: 1.2;
    align-items: center;
    padding: 0.5rem 1rem;
    a {
      text-decoration: none;
      color: white;
    }

    p {
      margin: 0;
      cursor: pointer;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .subsection {
    // display: none;
    overflow: hidden;
    background-color: white;
    max-height: 0;
    transition: max-height 0.01s ease-out;
    a {
      text-decoration: none;
      color: black;
    }
  }

  .nav-links:last-child {
    .subsection {
      .subsection-block:last-child {
        margin-bottom: 2rem;
      }
    }
  }

  .selected {
    text-decoration: none;

    // .module-link {
    //     background-color: white;
    //     color: black;
    //     z-index: 10;
    // }

    .subsection {
      text-decoration: none;
      max-height: 1000px;
      transition: max-height 1s ease-in;
      //  width: 100%;
      // display: initial;

      .subsection-block {
        .sub-selected {
          text-decoration: none;
          box-shadow: 5px 5px 5px $grey1;

          .subsection-links {
            text-decoration: none;
            color: black !important;
            background-color: $dark-blue !important;
            border-top: solid 1px $dark-blue;
            border-bottom: solid 1px $dark-blue;
            &:hover {
              transition: background-color 0.2s ease-in;
              background-color: $accent;
              color: white;
            }
          }
        }
        a {
          .subsection-links {
            background-color: white;
            border: none;
            padding: 1em;
            text-align: left;
            &:hover {
              // transition: background-color 0.2s ease-in;
              background-color: $accent2;
            }
          }
        }
      }
    }
  }
}

.sidebar-wrap:first-child {
  height: calc(100% - $header-height);
  // overflow: scroll;
}
.link- [class~='nav-links']:last-of-type {
  .subsection {
    margin-bottom: 10em;
  }
}

.toggle-menu {
  z-index: 101;
  height: 2.5em;
  width: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  visibility: none;

  .line1 {
    border: solid 1px black;
    width: 100%;
    transform: rotate(-45deg);
  }

  .line2 {
    border: solid 1px black;
    width: 100%;
    transform: rotate(45deg);
  }
}

.close {
  height: 1em;
  width: 1em;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../../../assets/images/close.png');
}

.close:hover {
  cursor: pointer;
}

.toggle-menu:hover {
  cursor: pointer;
}

.subsection-links {
  font-size: 1rem;
}

.not {
  filter: grayscale(1);
  opacity: 0.8;
  pointer-events: none;
}
.link-disabled {
  pointer-events: none;
}
