@import '../../../assets/variables.scss';

.reflections-questions-wrap {

  width: 100%;
}

.reflect-wrap {
  background-color: $color3;
  width: calc(100% - 1em);
  margin-bottom: 1em;
  padding: .5em;

  .reflect-header-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon {
    margin-right: 1em;
    height: 3.5em;
    width: 3em;
  }

  h2 {
    color: $accent;
  }

  text-align: left;
  border-radius: 10px;
  border: $border;
  box-shadow: 5px 5px 5px $grey1;

  .rQuestion {
    font-size: 1.3em;
  }

  .rq-intro {
    padding-left: 1em;
    font-size: 1.4em;
    font-weight: 550;
  }
}

.answers-reflect-wrap {
  display: flex;
  flex-direction: column;
  // justify-content: left;
  min-height: 3em;
  font-size: 1.2em;

  .answers {
    width: 20em;
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;

    .radio-options {
      width: 5em;
      display: flex;
      justify-content: left;
      align-items: center;
    }
  }


}

.reflect-response {
  width: 100%;
  font-size: 1em;
}