$accent: #368bbc;

$accent2: #368bbc;
$color2: #262e68;
$color1: #262e68;
$dark-blue: #ffcd2e;

$grey1: #acacac;
$grey2: #575656;

// $accent: #a9201f;

// $accent2: #1f63a9;
// $color2: #1fa8a9;
// $color1: #63a91f;
// $dark-blue: #651fa9;

// $grey1: #acacac;
// $grey2: #575656;

$color3: white;
$color4: white;
$header-height: 4.5rem;
$sidebar-width: 20em;
$subheader-height: 3.5em;
$border: solid 1px black;
$min-width-content: 30em;
$box-shadow: 5px 5px 5px $grey1;
$course-material-width: calc(100% - $sidebar-width);
$course-material-min-width: none;

$font: "Source Sans Pro", sans-serif;
$font2: "Source Sans Pro", sans-serif;
