@import "../../../assets/variables.scss";

.static-wrap {
  align-items: left;
  justify-content: left;
  display: flex;
  padding: 0.5em;
  border-radius: 10px;
  // border: $border;
  // box-shadow: 5px 5px 5px $grey1;
  margin-bottom: 1em;
  margin-top: 1em;
}

.static {
  font-size: 1.25rem;
  overflow: hidden;
  width: 100%;
  li {
    margin: 0.5rem 0;
  }
  ol {
    > ul {
      margin-bottom: 1.25rem;
    }
  }
}
