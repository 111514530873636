@import "../../../assets/variables.scss";
.progressbar-wrap {
  display: flex;
  // width: 80%;
  justify-content: left;
  align-items: center;
  font-size: 1vw;
  // margin: 0 auto;
  // margin-bottom: 2em;
}
.progressbar-wrap > * {
  display: flex;
  align-items: center;
}
.circle-link {
  height: 2em;
  width: 2em;
  max-width: 28px;
  max-height: 28px;
  //pointer-events: none;

  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.4s;
}

.hover {
  visibility: hidden;
  background-color: black;
  color: white;
  position: relative;
  font-size: 0.8em;
  width: 350%;
  top: -2em;
}

.circle-link:hover {
  .hover {
    visibility: visible;
  }
}

.completed {
  background-color: $color2;
  opacity: 0.5em;
  transition: 0.2s background-color;
}
.circle-selected {
  background-color: $dark-blue;
}
.line {
  width: 3em;
  height: 0.2em;
  content: "";
  background-color: black;
}
