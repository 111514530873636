@import "../../../assets/variables.scss";

.timeline-wrap {
  display: flex;
  flex-direction: column;
  justify-content: top;
  height: 100%;
  width: 100%;
  // width: calc(100% - $sidebar-width);
  // overflow: scroll;
  // margin-left: $sidebar-width;
}

.timeline {
  display: flex;
  height: 100%;
}
.content {
  width: 100%;
}

.sub-section-title {
  // font-size: 1.3em;
  font-weight: 600;
  height: $subheader-height;
  line-height: $subheader-height;
  margin: 0 1em;
  overflow: hidden;
  display: none;
  p {
    font-size: 0.9em;
  }
}

.slide-wrap {
  pointer-events: unset;
}
.slide-wrap {
  display: flex;
  flex-direction: column;
  justify-content: top;
  // flex-wrap: wrap;
  margin: 0em 0em 0em 0;
  align-items: left;
  text-align: left;
  // height: calc(100%) ;
  padding: 0 2em 7em 2em;
  width: calc(100% - 4em);
}
.fade-content {
  z-index: 0;

  @media screen and (max-width: 481px) {
    p,
    span,
    li {
      font-size: 1rem;
    }
  }
}

.top-section {
  position: fixed;
  z-index: 1;
  top: $header-height;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 3.5em;
  width: 100%;
  .progressbar-wrap {
  }
  // line-height: 3.5em;
  .sub-section-title {
    p {
      margin: 0;
      font-size: 1.3em;
    }
  }
}

.vp-center {
  align-content: flex-start;
}

.video-wrap {
  aspect-ratio: 16 / 9;
  max-width: 788px;
  margin: 2em 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  div {
    height: 100%;
    width: 100%;
  }

  iframe {
    align-self: flex-start;
    height: 100%;
    width: 100%;
    .vp-center {
      align-items: left;
      background-color: black;
    }
  }
  // background-color: aqua;
}

.title {
  margin: 1em 0 0 0;
  text-align: center;
  width: 100%;
  font-size: 1.5rem;
  margin-top: 0;
}

@media screen and (max-width: 481px) {
  .top-section {
    margin: auto;
    width: 100%;

    .progressbar-wrap {
      transform: scale(1.2);
    }
  }
}

.image-wrap {
  padding-top: 2em;
  .contentImage {
    text-align: center;
    img {
      width: 100%;
      max-width: 300px;
    }
  }
}
