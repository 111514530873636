@use '../../../assets/reusable.scss';
@import '../../../assets/variables.scss';

.welcome {
  position: relative;
  margin: auto;
  width: 100%;
  margin: 0 2rem;
  .top-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: -webkit-auto;
  }
  .continue-button {
    @include reusable.bigButton(white, $accent2, $accent2, white);
    background-color: $accent2;
    font-size: 1.5rem;
    margin-bottom: 0;

    &:first-child {
      margin-top: 0;
    }
    &:hover {
      animation: none;
      box-shadow: 8px 8px $accent2;
    }
  }
  .module-button {
    @include reusable.bigButton(white, $color2, $color2, white);
    animation: backAndforth 1s linear infinite;
    background-color: $color2;
    font-size: 1.5rem;
    &:hover {
      animation: none;
      box-shadow: 8px 8px $color2;
    }
    animation: none;
    margin: 0.25rem;
    &.disable {
      cursor: default;
      background: grey;
      border: grey solid 1px;
      pointer-events: none;
    }
  }

  h1 {
    font-size: 4rem;
    margin: 0 auto;
    margin-top: 0;
    font-weight: 400;
    text-align: center;
  }
  .middle-wrapper {
    margin: 1rem 4rem;
    background: #80808014;
    padding: 2rem 1rem;
    border-radius: 10px;
    h3 {
      font-size: 1.5rem;
      text-align: start;
    }
    h2 {
      margin-top: 0;
      font-size: 2rem;
      color: $dark-blue;
    }
    .badge-flex {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 16px;
      margin-bottom: 2rem;
    }
    .continue-flex {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    section {
      box-sizing: border-box;
      flex: 1;
      padding: 1rem;
    }
  }
}

.greyed-out {
  filter: grayscale(1);
}

.sub-mod {
  margin-bottom: 3rem;
}

.supportButton {
  display: flex;
  gap: 10px;
  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 1rem;
  color: red;
  font-weight: 500;
  border: red solid 2px;
  top: -36px;
  right: 20px;
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  svg {
    height: 30px;
    > * {
      fill: white;
      stroke: $color2;
    }
  }

  p {
    margin: 0;
  }

  &:hover {
    color: white;
  }
}

a.supportWrapper {
  padding: 1rem;
  margin: 2rem 0;
  margin: 1rem;
  @include reusable.bigButton(white, $accent2, $accent2, white);
  box-sizing: border-box;
  background-color: $accent2;
  font-size: 1.5rem;
  margin-bottom: 0;
  &:hover {
    animation: none;
    box-shadow: 8px 8px $accent2;
  }
  width: 100%;
  justify-content: center;
  padding: 0.75rem;
  position: static;
}

.front-button-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1rem 0 2rem 0;
}

@media screen and (max-width: 481px) {
  .welcome {
    h1 {
      font-size: 3rem;
    }
    .continue-button {
      font-size: 1rem;
    }
    .middle-wrapper {
      margin: 0;
    }
  }
}

.center-me {
  display: flex;
  margin-bottom: 1rem;
}

@include reusable.backAndforth;

.disabled {
  > .continue-button {
    cursor: default;
    background: grey;
    border: grey solid 1px;
    box-shadow: none;
    pointer-events: none;
  }
}

.flex-center {
  display: flex;
  justify-content: center;

  .takeMeWhere {
    background-color: #333b70;
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    font-size: 1.25rem;
    font-weight: 800;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    gap: 10px;
    &:hover {
      background: #602c83;
    }

    svg {
      height: 20px;
    }
  }
}

.legendWrap {
  background: white;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  gap: 0 1rem;
  @media screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: center;
  }
}

.legend {
  display: flex;
  margin: 1rem 0;
  align-items: center;
  img {
    width: 40px;
  }
  span {
    text-align: left;
    font-size: 1rem;
    margin-left: 10px;
    font-weight: 700;
  }
}
