@import "../../../assets/variables.scss";
.item-wrap {
  text-align: left;
  margin: 1em;
  display: flex;
  flex-direction: column;
  .question {
    font-size: 1rem;
    font-weight: 700;
    margin: 0.2em;
  }
}

.answers-wrap {
  padding: 0.5em;
  margin-left: 2em;
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-size: 1rem;
  input[type="radio"] {
    min-width: 15px;
  }
  @media screen and (max-width: 481px) {
    margin: 0;
    padding: 0.5em 0;
  }
}
.quiz-response-correct {
  color: green;
  background-color: lightgreen;
  padding: 1em;
  border-radius: 5px;
  font-weight: 700;
}
.quiz-response-incorrect {
  color: red;
  background-color: pink;
  padding: 1em;
  border-radius: 5px;
  font-weight: 700;
}

.question-info {
  margin: 1rem;
  margin-bottom: 1.5rem;
  font-size: 1.15rem;
  color: red;
  @media screen and (max-width: 481px) {
    margin: 0;
  }
}

.question-wrap {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  margin: 2rem 0;
  background: rgba(211, 211, 211, 0.287);
  padding: 1rem;
  @media screen and (max-width: 481px) {
    .question-info {
      font-size: 1rem;
    }
    font-size: 1rem;
  }
}
