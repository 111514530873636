@import '../../../assets/variables.scss';
.click-content{
  display: flex; 
  flex-wrap: wrap;
  justify-content: center;
  .clickable-reveal{
    width: 25%;
    height: 8em;
  line-height: 8em;
  text-align: center;
    background-color: $color2;
    color: white;
    border-radius: 10px;
    font-size: 1.3em;;
    margin: 1em;
   
  } .clicked{
      background-color: $accent;
    }
  .clickable-reveal:hover{
    cursor: pointer;
  }
}